<template>
  <div class="form-group">
    <div class="clearfix">
      <div class="input-line my-input-form-text">
        <a
          v-if="type == 'password'"
          href="#"
          class="show-password"
          @click="switchVisibility"
        >
          <img
            src="@/assets/img/ic-eyes.svg"
            :class="{ 'opacity-2': passwordFieldType == 'password' }"
            alt=""
          />
        </a>
        <input
          v-model="model"
          :type="type == 'password' ? passwordFieldType : type ? type : 'text'"
          class="form-control rounded-0"
          :class="classComponent"
          :disabled="disabled"
          :max="type == 'date' ? max : null"
          :maxlength="maxlength"
          :readonly="readonly"
          placeholder=" "
          @input="input($event.target.value)"
          :required="required"
          :style="phonePrefix ? '--padding : 10px 10px 5px 40px' : ''"
        />
        <span class="phone-prefix" v-if="phonePrefix"></span>
        <label>
          {{ label }} <span class="text-danger" v-if="redAsterisk">*</span>
        </label>
        <!-- <div class="valid-feedback text-right">{{label.replace('*', '')}} is valid!</div> -->
        <div
          class="invalid-feedback text-right"
          v-html="
            invalidMessage && invalidMessage.length
              ? invalidMessage.filter((el) => el).join('<br/>')
              : ''
          "
        ></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "MyInputFormText",
  props: [
    "value",
    "id",
    "label",
    "placeholder",
    "description",
    "classComponent",
    "type",
    "invalidMessage",
    "disabled",
    "max",
    "maxlength",
    "readonly",
    "required",
    "redAsterisk", // add "*" to label with red color
    "phonePrefix", // phone prefix text "+62"
  ],
  data() {
    return {
      model: this.value,
      passwordFieldType: this.type,
    };
  },
  methods: {
    input(val) {
      this.model =
        this.type == "number" && this.maxlength
          ? val.slice(0, this.maxlength)
          : val;
      this.$emit("input", this.model);
    },
    switchVisibility() {
      this.passwordFieldType =
        this.passwordFieldType == "password" ? "text" : "password";
    },
  },
  watch: {
    value(newValue) {
      this.model = newValue;
    },
  },
};
</script>
<style scoped>
.input-line input:not(:placeholder-shown) ~ label {
  top: -10px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #333;
}
.input-line input:focus + .phone-prefix:before,
.input-line input:not(:placeholder-shown) + .phone-prefix:before {
  content: "+62";
  display: block;
  position: absolute;
  /* top: 50%; */
  top: 10px;
  /* transform: translateY(-40%); */
  left: 10px;
  z-index: 1;
  font-weight: 500;
  font-size: 14px;
  line-height: 23px;
}
.box-wizard .input-line input {
  height: initial;
}
</style>
